import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React, { useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { IoIosArrowForward } from "react-icons/io";
import LazyLoad from "react-lazyload";
import ReactPlayer from "react-player";
import bgShape from "../images/two-column-bgshape.png";
import VideoIcon from "../images/video.svg";

const TwoColumnImageContent = ({
  heading,
  newTab,
  subTitle,
  tagline,
  content,
  btnCta1,
  btnCta2,
  image,
  video,
  videoUrl,
  bgColor,
  mainBgColor,
  flip = false,
  textColor,
  bodyTextColor = "#252525",
  borderColor = "#0000001a",
  bgImage,
  border = true,
  borderSize = 2,
  overlayImage,
  isBgShape = false,
  noTopPadding,
  contentLogos,
}) => {
  const [showPlayBtn, setShowPlayBtn] = useState(true);

  const vidRef = useRef(null);
  const handlePlayVideo = () => {
    vidRef.current.play();
    setShowPlayBtn(!showPlayBtn);
  };

  return (
    <section
      className={`${
        noTopPadding ? "pb-5 pb-md-8" : "py-5 py-md-8"
      } two-column-wrapper`}
      style={
        (bgImage || mainBgColor || isBgShape) &&
        sectionStyle(bgImage, mainBgColor, isBgShape)
      }
    >
      <Container>
        <Row
          className={`align-items-lg-center ${flip ? "flex-row-reverse" : ""}`}
        >
          <Col
            lg={6}
            className={`mb-4 mb-lg-0 text-start ${
              flip ? "ps-lg-6" : "pe-lg-6"
            }`}
          >
            <span style={{ color: textColor }}>{tagline}</span>
            <h2 className="my-0 pt-2" style={{ color: bodyTextColor }}>
              {heading}
            </h2>
            {subTitle && <p className="mt-4 d-block">{subTitle}</p>}
            {content && (<div
              className="py-4"
              style={{ color: bodyTextColor }}
              dangerouslySetInnerHTML={{ __html: content }}
            ></div>)}
            {contentLogos && contentLogos.length > 0 && (
              <Row className="align-items-center justify-content-center justify-content-md-start g-3">
                {contentLogos.map((item, index) => (
                  <Col xs={6} sm={4} key={index}>
                    <GatsbyImage
                      image={
                        item.logo?.node?.localFile?.childImageSharp
                          .gatsbyImageData
                      }
                      alt={item?.logo?.node?.altText}
                      className="w-100 h-auto"
                    />
                  </Col>
                ))}
              </Row>
            )}
            {newTab !== true && (
              <div className="d-flex align-items-lg-center flex-column flex-md-row">
                {btnCta1 && btnCta1.url && (
                  <Button
                    as={Link}
                    href={btnCta1.url}
                    size="lg"
                    className="px-4 py-3 me-1 montserrat-bold fs-6 mb-3 mb-lg-0"
                    target={btnCta1.target}
                    variant="secondary"
                  >
                    {btnCta1.title}
                  </Button>
                )}
                {btnCta2 && btnCta2.url && (
                  <Button
                    as={Link}
                    href={btnCta2.url}
                    size="lg"
                    className="px-4 py-3 montserrat-bold fs-6 bg-transparent border-0"
                    target={btnCta2.target}
                    style={{ color: textColor }}
                  >
                    {btnCta2.title}
                    <IoIosArrowForward className="fs-4 ms-2" />
                  </Button>
                )}
              </div>
            )}
            {newTab === true && (
              <div className="d-flex align-items-lg-center flex-column flex-md-row">
                {btnCta1 && btnCta1.url && (
                  <Button
                    href={btnCta1.url}
                    size="lg"
                    className="px-4 py-3 me-1 montserrat-bold fs-6 mb-3 mb-lg-0"
                    target="_blank"
                    rel="noreferrer"
                    variant="secondary"
                  >
                    {btnCta1.title}
                  </Button>
                )}
                {btnCta2 && btnCta2.url && (
                  <Button
                    href={btnCta2.url}
                    size="lg"
                    className="px-4 py-3 montserrat-bold fs-6 bg-transparent border-0"
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: textColor }}
                  >
                    {btnCta2.title}
                    <IoIosArrowForward className="fs-4 ms-2" />
                  </Button>
                )}
              </div>
            )}
          </Col>
          <Col
            lg={6}
            className={`mb-4 mb-lg-0 text-start pt-4 ${
              flip ? "pe-lg-4" : "ps-lg-4"
            } position-relative`}
          >
            {image && image.sourceUrl ? (
              <div
                className={`position-relative ${
                  overlayImage ? "overlay-wrapper" : ""
                }`}
              >
                {!overlayImage && (
                  <GatsbyImage
                    image={image.localFile?.childImageSharp?.gatsbyImageData}
                    alt={image.altText}
                    width="100%"
                    className="rounded-5 object-fit-cover main-image "
                    style={{
                      border: border
                        ? `${borderSize}px solid ${borderColor}`
                        : 0,
                      width: overlayImage ? "75%" : "100%",
                    }}
                  />
                )}
                {overlayImage && (
                  <div>
                    <GatsbyImage
                      image={image.localFile.childImageSharp.gatsbyImageData}
                      alt={image.altText}
                      width="100%"
                      className="rounded-5 object-fit-cover main-image d-none d-md-block"
                      style={{
                        border: border
                          ? `${borderSize}px solid ${borderColor}`
                          : 0,
                        width: overlayImage ? "75%" : "100%",
                      }}
                    />
                    <GatsbyImage
                      image={
                        overlayImage?.localFile.childImageSharp.gatsbyImageData
                      }
                      alt={overlayImage?.altText}
                      width="auto"
                      className="rounded-5 object-fit-cover border border-2 border-white d-none d-md-block p-0 position-absolute main-image-overlay"
                    />
                  </div>
                )}
                {overlayImage && (
                  <div>
                    <GatsbyImage
                      image={image.localFile.childImageSharp.gatsbyImageData}
                      alt={image.altText}
                      width="100%"
                      className="rounded-5 object-fit-cover main-image d-md-none"
                      style={{
                        border: border
                          ? `${borderSize}px solid ${borderColor}`
                          : 0,
                        width: "100%",
                      }}
                    />
                    <GatsbyImage
                      image={
                        overlayImage?.localFile.childImageSharp.gatsbyImageData
                      }
                      alt={overlayImage?.altText}
                      width="auto"
                      className="rounded-5 object-fit-cover  mt-3 p-0  d-md-none"
                    />
                  </div>
                )}
              </div>
            ) : (
              <>
                {video && video.mediaItemUrl && !videoUrl && (
                  <LazyLoad height={200} offset={100}>
                    <div>
                      <video
                        ref={vidRef}
                        width="100%"
                        controls
                        height="auto"
                        className="rounded-5 object-fit-cover"
                      >
                        <source
                          src={videoUrl || video.mediaItemUrl}
                          type="video/mp4"
                        />
                      </video>

                      {showPlayBtn && (
                        <VideoIcon
                          onClick={handlePlayVideo}
                          className="position-absolute mx-auto h-100 start-0 end-0 top-0 bottom-0"
                        />
                      )}
                    </div>
                  </LazyLoad>
                )}
                {videoUrl && (
                  <LazyLoad height={200} offset={100}>
                    <div style={{ overflow: "hidden" }} className="rounded-5 ">
                      <ReactPlayer width="100%" controls url={videoUrl} />
                    </div>
                  </LazyLoad>
                )}
              </>
            )}
            {bgColor && (
              <div
                className={`bg-image position-absolute w-50 h-50 top-0 ${
                  flip ? "right-0" : "left-0"
                }`}
                style={overlayImageStyle(flip)}
              >
                {flip ? (
                  <div
                    className="d-none d-md-block"
                    style={{
                      background: bgColor,
                      width: "100%",
                      height: "100%",
                      borderBottomRightRadius: "30px",
                      borderTopLeftRadius: "30px",
                    }}
                  ></div>
                ) : (
                  <div
                    className="d-none d-md-block"
                    style={{
                      background: bgColor,
                      width: "100%",
                      height: "100%",
                      borderTopRightRadius: "30px",
                      borderBottomLeftRadius: "30px",
                    }}
                  ></div>
                )}
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export const sectionStyle = (bgImage, bgColor, isBgShape) => {
  if (!bgImage) {
    if (!isBgShape) {
      return {
        backgroundColor: bgColor,
      };
    } else {
      return {
        backgroundImage: `url(${bgShape})`,
        backgroundColor: bgColor,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "top right",
        backgroundSize: "40%",
      };
    }
  }
  return {
    background: `linear-gradient(0deg, ${bgColor} 0%, ${bgColor} 100%), url(${bgImage}) lightgray 50% / cover no-repeat`,
  };
};

export const overlayImageStyle = (flip) => ({
  zIndex: "-1",
  left: flip ? "unset" : 0,
  right: flip ? 0 : "unset",
});

export default TwoColumnImageContent;
