import React, { useState, useEffect } from "react";
import { useForm, ValidationError } from "@formspree/react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
const freeEmailDomains = require("free-email-domains");

function ContactForm({
	formButtonText,
	formTitle,
	slug,
	redirect,
	redirectUrl,
}) {
	const [state, handleSubmit] = useForm("xrbgeolp");
	const [gclid, setGclid] = useState(""); // New state to store gclid
	const [utm, setUtm] = useState(""); // New state to store gclid
	const newRedirectUrl =
		redirectUrl || `https://zcal.co/t/ncz/award?id=${slug}`;
	const [emailError, setEmailError] = useState("");

	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);

		const gclidParam = urlParams.get("gclid");
		const utmParam = urlParams.get("utm_medium");
		if (gclidParam) {
			setGclid(gclidParam); // Store the gclid in state if it exists
		}

		if (utmParam) {
			setUtm(utmParam); // Store the gclid in state if it exists
		}
	}, []);

	const handleFormSubmit = (event) => {
		event.preventDefault(); // Prevent default form submission

		const formData = new FormData(event.target);
		const customerEmail = formData.get("email");
		const customerPhone = formData.get("phone");
		const customerName = formData.get("name");

		const emailDomain = customerEmail.split("@")[1] || "";

		// 2. Check if the domain is in the free email domains list
		if (freeEmailDomains.includes(emailDomain.toLowerCase())) {
			setEmailError("Please use a business email address.");
			return; // Stop execution, do NOT submit to Formspree
		} else {
			setEmailError(""); // Clear any previous error
		}

		// Push to dataLayer after form submit
		if (typeof window !== "undefined" && window.dataLayer) {
			window.dataLayer.push({
				event: "generate_lead",
				form_name: "ContactForm", // You can change this based on the form name
				customer_email: customerEmail,
				customer_phone_number: customerPhone,
			});
		}

		handleSubmit(event); // Proceed with Formspree submission
	};

	if (state.succeeded) {
		if (redirect === true) {
			window.location.href = newRedirectUrl;
		} else {
			window.location.href = newRedirectUrl;
		}
	}

	return (
		<Form className="w-100" onSubmit={handleFormSubmit}>
			{formTitle && <h6 className="mb-3">{formTitle}</h6>}

			<Row>
				<Col>
					<Form.Group className="mb-3" controlId="firstName">
						<Form.Label className="montserrat-bold medium-text">
							First Name <span className="text-danger">*</span>
						</Form.Label>
						<Form.Control
							name="firstName"
							type="text"
							style={{ height: "48px" }}
							required
						/>
					</Form.Group>
					<ValidationError
						prefix="firstName"
						field="firstName"
						errors={state.errors}
					/>
				</Col>
			</Row>
			<Row>
				<Col>
					<Form.Group className="mb-3" controlId="lastName">
						<Form.Label className="montserrat-bold medium-text">
							Last Name <span className="text-danger">*</span>
						</Form.Label>
						<Form.Control
							name="lastName"
							type="text"
							style={{ height: "48px" }}
							required
						/>
					</Form.Group>
					<ValidationError
						prefix="lastName"
						field="lastName"
						errors={state.errors}
					/>
				</Col>
			</Row>
			<Row>
				<Col>
					<Form.Group className="mb-3" controlId="telephone">
						<Form.Label className="montserrat-bold medium-text">
							Email <span className="text-danger">*</span>
						</Form.Label>
						<Form.Control
							type="email"
							name="email"
							style={{ height: "48px" }}
							required
						/>
					</Form.Group>
					{/* Display the email error if it exists */}
					{emailError && (
						<p className="text-danger" style={{ marginTop: -12 }}>
							{emailError}
						</p>
					)}

					<ValidationError prefix="Email" field="email" errors={state.errors} />
				</Col>
			</Row>
			<Row>
				<Col>
					<Form.Group className="mb-4" controlId="phone">
						<Form.Label className="montserrat-bold medium-text">
							Phone <span className="text-danger">*</span>
						</Form.Label>
						<Form.Control
							type="tel"
							name="phone"
							style={{ height: "48px" }}
							required
						/>
					</Form.Group>
				</Col>
			</Row>
			<Row>
				<Col>
					<Form.Group className="mb-3" controlId="businessWebsiteUrl">
						<Form.Label className="montserrat-bold medium-text">
							Business Website URL <span className="text-danger">*</span>
						</Form.Label>
						<Form.Control
							type="text"
							name="businessWebsiteUrl"
							style={{ height: "48px" }}
							required
						/>
					</Form.Group>

					<ValidationError
						prefix="BusinessWebsiteUrl"
						field="businessWebsiteUrl"
						errors={state.errors}
					/>
				</Col>
			</Row>
			<Row>
				<Col>
					<Form.Group className="mb-3" controlId="position">
						<Form.Label className="montserrat-bold medium-text">
							What is Your Position at the Company?{" "}
							<span className="text-danger">*</span>
						</Form.Label>
						<Form.Control
							name="position"
							type="text"
							style={{ height: "48px" }}
							required
						/>
					</Form.Group>

					<ValidationError
						prefix="Position"
						field="position"
						errors={state.errors}
					/>
				</Col>
			</Row>

			{/* Hidden field for gclid */}
			<Form.Group controlId="gclid" className="d-none">
				<Form.Control type="hidden" name="gclid" value={gclid} />
			</Form.Group>
			<Form.Group controlId="utmId" className="d-none">
				<Form.Control type="hidden" name="utmId" value={utm} />
			</Form.Group>
			<Form.Group controlId="page" className="d-none">
				<Form.Control type="hidden" name="page" value={slug} />
			</Form.Group>

			<Button
				size="small"
				className="px-4 py-3 mt-2 montserrat-bold fs-6 btn btn-secondary w-100"
				type="submit"
				id="contact-send-btn"
				disabled={state.submitting}
			>
				{formButtonText || "Book Free Consultation"}
			</Button>
		</Form>
	);
}

export default ContactForm;
