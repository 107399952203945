import { Link } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";
import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import ContactForm from "./landing-contact-form";

const HeroBannerSmall = ({
	heading,
	content,
	bgImage,
	textAlign = "start",
	formTitle,
	formButtonText,
	btnCta1,
	slug,
	redirect,
	hideForm,
	redirectUrl,
}) => {
	const image = getImage(bgImage); // Prepare the image data for BgImage
	const overlayColor = "rgba(0, 0, 0, 0.50)";
	const backgroundStyle = {
		marginTop: "-105px",
		paddingTop: "170px",
		paddingBottom: "112px",
		backgroundRepeat: "no-repeat",
		backgroundSize: "cover",
		minHeight: "660px",
	};
	const overlayStyle = {
		background: `linear-gradient(${overlayColor} 0%, ${overlayColor} 100%)`,
		backgroundBlendMode: "multiply",
		zIndex: 1,
	};

	const bannerContent = (
		<>
			<Row className={`justify-content-between align-items-center`}>
				<Col lg={6} className={`mb-4 mb-lg-0 text-${textAlign}`}>
					<h1 className="my-auto text-white pb-2 banner-heading">
						<span>{heading}</span>
					</h1>
					<div
						className="text-white py-4"
						dangerouslySetInnerHTML={{ __html: content }}
					></div>
					<div className="d-flex align-items-center">
						{btnCta1 && btnCta1.url && (
							<Button
								as={Link}
								href={btnCta1.url}
								size="lg"
								className="px-4 py-3 me-3 montserrat-bold fs-6"
								target={btnCta1.target}
								variant="secondary"
							>
								{btnCta1.title}
							</Button>
						)}
					</div>
				</Col>
				{hideForm !== true && (
					<Col lg={6} xl={5} className="mb-lg-0 text-start text-md-start">
						<div className="bg-white p-4 m-lg-4 rounded-4">
							<ContactForm
								redirectUrl={redirectUrl}
								redirect={redirect}
								formButtonText={formButtonText}
								formTitle={formTitle}
								slug={slug}
							/>
						</div>
					</Col>
				)}
			</Row>
		</>
	);

	return (
		<BgImage
			image={image}
			className="d-flex align-items-center position-relative landing-banner"
			style={backgroundStyle}
		>
			<div
				className="position-absolute start-0 top-0 h-100 w-100"
				style={overlayStyle}
			></div>
			<Container style={{ zIndex: 2 }} className="position-relative">
				{bannerContent}
			</Container>
		</BgImage>
	);
};

export default HeroBannerSmall;
