import { Link } from "gatsby";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";
import React, { useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";

const HeroBannerSmall = ({
	heading,
	content,
	btnCta1,
	btnCta2,
	bgImage,
	bannerLogos,
	twoColumn = false,
	tagline = "",
	showUnderline = true,
	textAlign = "start",
	columns = 6,
	isShowCalculateLink = false,
	isShowBtnLinkIcon = false,
}) => {
	const [isShowCalcLink, setIsShowCalcLink] = useState(isShowCalculateLink);

	const image = getImage(bgImage); // Prepare the image data for BgImage
	const overlayColor = "rgba(0, 0, 0, 0.50)";
	const backgroundStyle = {
		marginTop: "-105px",
		paddingTop: isShowCalcLink ? "258px" : "210px",
		paddingBottom: bannerLogos && bannerLogos.length > 0 ? "60px" : "150px",
		backgroundRepeat: "no-repeat",
		backgroundSize: "cover",
		minHeight: "660px",
	};
	const overlayStyle = {
		background: `linear-gradient(${overlayColor} 0%, ${overlayColor} 100%)`,
		backgroundBlendMode: "multiply",
		zIndex: 1,
	};

	const bannerContent = (
		<>
			<Row className={`justify-content-${textAlign}`}>
				{twoColumn ? (
					<>
						<Col lg={6} className={`mb-4 mb-lg-0 text-${textAlign}`}>
							<span className="text-white">{tagline}</span>
							<h1 className="my-auto text-white pb-2">{heading}</h1>
						</Col>
						<Col lg={6} className="mb-4 mb-lg-0 text-start text-md-start">
							<div
								className="text-white py-4"
								dangerouslySetInnerHTML={{ __html: content }}
							></div>
							<div className="d-flex align-items-center flex-column flex-md-row">
								{btnCta1 && btnCta1.url && (
									<Button
										as={Link}
										href={btnCta1.url}
										size="lg"
										className="px-4 py-3 me-3 montserrat-bold fs-6 d-flex align-items-center w-100 w-md-auto justify-content-center"
										target={btnCta1.target}
										variant="secondary"
									>
										{btnCta1.title}
										{isShowBtnLinkIcon && (
											<StaticImage
												src={"../images/icons/link.png"}
												alt="Netural Carbone Zone"
												className="ms-2"
												layout="fixed"
												height={20}
												imgStyle={{ filter: "invert(1)" }}
											/>
										)}
									</Button>
								)}
								{btnCta2 && btnCta2.url && (
									<Button
										as={Link}
										href={btnCta2.url}
										size="lg"
										className="px-4 py-3 montserrat-bold fs-6 bg-transparent text-white border-white w-100 w-md-auto mt-4 mt-md-0"
										target={btnCta2.target}
									>
										{btnCta2.title}
									</Button>
								)}
							</div>
						</Col>
					</>
				) : (
					<Col
						md={9}
						xxl={columns}
						className={`mb-4 mb-lg-0 text-${textAlign}`}
					>
						<h1
							className={`my-auto text-white pb-2 ${
								showUnderline ? "banner-heading" : ""
							}`}
						>
							<span>{heading}</span>
						</h1>
						<div
							className="text-white py-4"
							dangerouslySetInnerHTML={{ __html: content }}
						></div>
						<div className="d-flex align-items-center flex-column flex-md-row">
							{btnCta1 && btnCta1.url && (
								<Button
									as={Link}
									href={btnCta1.url}
									size="lg"
									className="px-4 py-3 me-md-3 montserrat-bold fs-6 d-flex align-items-center w-100 w-md-auto justify-content-center"
									target={btnCta1.target}
									variant="secondary"
								>
									{btnCta1.title}{" "}
									{isShowBtnLinkIcon && (
										<StaticImage
											src={"../images/icons/link.png"}
											alt="Netural Carbone Zone"
											className="ms-2"
											layout="fixed"
											height={20}
											imgStyle={{ filter: "invert(1)" }}
										/>
									)}
								</Button>
							)}
							{btnCta2 && btnCta2.url && (
								<Button
									as={Link}
									href={btnCta2.url}
									size="lg"
									className="px-4 py-3 montserrat-bold fs-6 bg-transparent text-white border-white w-100 w-md-auto mt-4 mt-md-0"
									target={btnCta2.target}
								>
									{btnCta2.title}
								</Button>
							)}
						</div>
					</Col>
				)}
			</Row>
			{bannerLogos && bannerLogos.length > 0 && (
				<Row className="align-items-center mt-7 justify-content-center justify-content-md-start g-3">
					{bannerLogos.map((item, index) => (
						<Col xs={6} sm={4} key={index} lg={true}>
							<a
								target="_blank"
								rel="noreferrer"
								href={item?.link}
								className="social-link"
							>
								<GatsbyImage
									image={
										item.bannerLogo?.node?.localFile.childImageSharp
											.gatsbyImageData
									}
									alt={item.bannerLogo?.node?.altText}
									className="w-100 h-auto"
								/>
							</a>
						</Col>
					))}
				</Row>
			)}
		</>
	);

	const calculateLinkStyle = {
		top: "105px",
		zIndex: 1,
	};

	return (
		<BgImage
			image={image}
			className="d-flex align-items-center position-relative"
			style={backgroundStyle}
		>
			<div
				className="position-absolute start-0 top-0 h-100 w-100"
				style={overlayStyle}
			></div>
			{isShowCalcLink && (
				<div
					className={`py-3 py-md-2 bg-sky-blue position-absolute w-100 px-2 px-md-0`}
					style={calculateLinkStyle}
				>
					<Container style={{ zIndex: 2 }} className="position-relative">
						<Row className="justify-content-center py-1">
							<Col
								className="text-center d-flex flex-column flex-md-row align-items-md-center justify-content-center"
								lg={6}
							>
								<div className="d-flex align-items-center text-white montserrat-regular">
									<StaticImage
										src={"../images/icons/NCZ-white.svg"}
										alt="Netural Carbone Zone"
										className="me-2"
										layout="fixed"
									/>
									<span className="fs-6 montserrat-medium">
										Calculate your emissions for free
									</span>
								</div>
								<a
									href="https://app.neutralcarbonzone.com/241290444812856&amp;BAQ_id=ws"
									target="_blank"
									className="mt-2 mt-md-0 ms-2 ps-1 d-flex align-items-center text-white fs-6 montserrat-bold"
								>
									click here{" "}
									<StaticImage
										src={"../images/icons/link.png"}
										alt="Netural Carbone Zone"
										className="ms-2"
										layout="fixed"
										height={20}
									/>
								</a>
							</Col>
						</Row>
						<button
							className="bg-transparent border-0 shadow-none p-0 px-2 px-xl-0 position-absolute my-1 mx-2 mx-md-3 mx-xl-0 end-0 top-0"
							onClick={() => setIsShowCalcLink(false)}
						>
							<StaticImage
								src={"../images/icons/close.png"}
								alt="Netural Carbone Zone"
								className="me-2"
								layout="fixed"
								height={24}
							/>
						</button>
					</Container>
				</div>
			)}

			<Container style={{ zIndex: 2 }} className="position-relative">
				{bannerContent}
			</Container>
		</BgImage>
	);
};

export default HeroBannerSmall;
