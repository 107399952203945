import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import { StaticImage } from "gatsby-plugin-image";

const formattedDate = (date) => {
  const dateInfo = new Date(date);
  return dateInfo.toLocaleDateString("en-US", {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "2-digit",
  });
};

const EventCard = ({
  title,
  image,
  date,
  location,
  content,
  ctaButton,
  eventComingSoonLabel,
}) => {
  const [showModal, setShowModal] = useState(false);

  const truncateHtml = (htmlContent, maxLength) => {
    const div = document.createElement("div");
    div.innerHTML = htmlContent;

    let text = "";
    const stack = Array.from(div.childNodes);

    while (stack.length > 0) {
      const node = stack.shift();

      if (node.nodeType === Node.TEXT_NODE) {
        if (text.length + node.nodeValue.length > maxLength) {
          text += node.nodeValue.slice(0, maxLength - text.length) + " ...";
          break;
        }
        text += node.nodeValue;
      } else if (node.nodeType === Node.ELEMENT_NODE) {
        stack.unshift(...node.childNodes);
      }
    }

    return text;
  };

  const handleClose = () => setShowModal(false);
  
  return (
    <Col lg={6} className="mb-4">
      <Card className="w-100 h-100 rounded-24 event-card overflow-hidden">
        <Card.Body className="p-0 bg-pricing-card d-flex flex-column">
          <div className="d-flex justify-content-center p-4 px-6 bg-white">
            <GatsbyImage
              image={image?.localFile.childImageSharp.gatsbyImageData}
              alt={image?.altText}
              style={{
                height: "260px",
                maxWidth: "420px",
                objectFit: "contain",
              }}
              imgStyle={{ objectFit: "contain" }}
            />
          </div>

          <div className="p-4">
            {eventComingSoonLabel && (
              <span
                className="my-3 my-md-0 py-1 px-2 rounded text-white montserrat-medium fw-semibold"
                style={{ background: "rgba(255, 255, 255, 0.24)" }}
              >
                {eventComingSoonLabel}
              </span>
            )}
            <div className="d-flex align-items-start align-items-lg-center flex-column flex-md-row mt-2">
              <Card.Title className="d-flex fs-5 mb-0 text-white">
                {title}{" "}
              </Card.Title>
            </div>

            <div className="d-flex flex-wrap" style={{ fontSize: "14px" }}>
              {date && (
                <span className="montserrat-light me-3 text-white">
                  {formattedDate(date)}
                </span>
              )}
              <span className="montserrat-light text-white">
                Location: {location}
              </span>
            </div>
            <div className="pt-3">
              <span
                dangerouslySetInnerHTML={{
                  __html: truncateHtml(content, 150),
                }}
                className="event-card-content"
                style={{ flex: 1 }}
              ></span>
              <span
                className="text-white ms-1 montserrat-bold"
                onClick={() => setShowModal(true)}
                style={{ cursor: "pointer" }}
              >
                Read More
              </span>
            </div>
          </div>
          <div className="px-4 mb-4 mt-auto">
            {ctaButton && (
              <Button
                variant="secondary"
                size="sMedium"
                className="text-sm montserrat-bold fs-6 p-3 text-light-black w-100"
                as={Link}
                href={ctaButton?.url}
                target={ctaButton?.target ?? "_blank"}
              >
                {ctaButton.title}
              </Button>
            )}
          </div>
        </Card.Body>
      </Card>
      <Modal
        show={showModal}
        onHide={handleClose}
        className="overflow-hidden"
        centered
        size="lg"
        contentClassName="rounded-24 bg-pricing-card border-2 border-pricing-card"
      >
        <Modal.Header className="position-absolute border-0 p-0 event-modal-header">
          <Button
            variant="light"
            onClick={handleClose}
            style={{ border: "none" }}
          >
            <StaticImage
              src={"../images/modal-close.png"}
              alt="Netural Carbone Zone"
              className="ms-2"
              layout="fixed"
              height={32}
            />
          </Button>
        </Modal.Header>
        <Modal.Body
          style={{
            maxHeight: "calc(100vh - 100px)",
            overflowY: "auto",
          }}
          className="p-0 rounded-24"
        >
          <div className="d-flex justify-content-center p-4 px-6 bg-white">
            <GatsbyImage
              image={image?.localFile.childImageSharp.gatsbyImageData}
              alt={image?.altText}
              style={{ height: "260px", objectFit: "contain" }}
              imgStyle={{ objectFit: "contain" }}
            />
          </div>

          <div className="p-4">
            {eventComingSoonLabel && (
              <span
                className="my-3 my-md-0 py-1 px-2 rounded text-white montserrat-medium fw-semibold"
                style={{ background: "rgba(255, 255, 255, 0.24)" }}
              >
                {eventComingSoonLabel}
              </span>
            )}
            <div className="d-flex align-items-start align-items-lg-center flex-column flex-md-row mt-2">
              <Card.Title className="d-flex fs-5 mb-0 me-3 text-white">
                {title}{" "}
              </Card.Title>
            </div>

            <div className="d-flex flex-wrap" style={{ fontSize: "14px" }}>
              {date && (
                <span className="montserrat-light me-3 text-white">
                  {formattedDate(date)}
                </span>
              )}
              <span className="montserrat-light text-white">
                Location: {location}
              </span>
            </div>
            <div className="d-flex justify-content-between align-items-center pt-3 mb-2">
              <div
                dangerouslySetInnerHTML={{
                  __html: content,
                }}
                className="event-card-content"
                style={{ flex: 1 }}
              ></div>
            </div>
            {ctaButton && (
              <Button
                variant="secondary"
                size="sMedium"
                className="text-sm montserrat-bold fs-6 p-3 px-5 mt-0 text-light-black w-100 w-md-auto"
                as={Link}
                href={ctaButton?.url}
                target={ctaButton?.target ?? "_blank"}
              >
                {ctaButton.title}
              </Button>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </Col>
  );
};

export default EventCard;
