import { Link } from "gatsby";
import React, { useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import LazyLoad from "react-lazyload";
import ReactPlayer from "react-player";
import VideoIcon from "../images/video.svg";

const TwoColumnFaqVideo = ({
  heading,
  tagline,
  btnCta1,
  video,
  videoUrl,
  bgColor,
  flip = false,
  textColor,
  bodyTextColor = "#252525",
}) => {
  const [showPlayBtn, setShowPlayBtn] = useState(true);

  const vidRef = useRef(null);
  const handlePlayVideo = () => {
    vidRef.current.play();
    setShowPlayBtn(!showPlayBtn);
  };

  return (
    <section className={"py-3 py-md-6 two-column-wrapper"}>
      <Container>
        <Row
          className={`align-items-lg-center flex-column-reverse ${flip ? "flex-md-row-reverse" : "flex-md-row"}`}
        >
          <Col
            lg={6}
            className={`mb-4 mb-lg-0 text-start ${
              flip ? "ps-lg-6" : "pe-lg-6"
            }`}
          >
            <span style={{ color: bgColor }}>{tagline}</span>
            <h2 className="my-0 pt-2" style={{ color: bodyTextColor }}>
              {heading}
            </h2>

            {btnCta1 && btnCta1.url && (
              <Button
                as={Link}
                href={btnCta1.url}
                size="lg"
                className="px-4 py-3 me-1 montserrat-bold fs-6 mt-4"
                target={btnCta1.target}
                variant="secondary"
                style={{
                  backgroundColor: bgColor,
                  borderColor: bgColor,
                  color: textColor,
                }}
              >
                {btnCta1.title}
              </Button>
            )}
          </Col>
          <Col
            lg={6}
            className={`mb-4 mb-lg-0 text-start pt-4 ${
              flip ? "pe-lg-4" : "ps-lg-4"
            } position-relative`}
          >
            <>
              {video && video.mediaItemUrl && !videoUrl && (
                <LazyLoad height={200} offset={100}>
                  <div>
                    <video
                      ref={vidRef}
                      width="100%"
                      controls
                      height="auto"
                      className="rounded-5 object-fit-cover"
                    >
                      <source
                        src={videoUrl || video.mediaItemUrl}
                        type="video/mp4"
                      />
                    </video>

                    {showPlayBtn && (
                      <VideoIcon
                        onClick={handlePlayVideo}
                        className="position-absolute mx-auto h-100 start-0 end-0 top-0 bottom-0"
                      />
                    )}
                  </div>
                </LazyLoad>
              )}
              {videoUrl && (
                <LazyLoad height={200} offset={100}>
                  <div style={{ overflow: "hidden" }} className="rounded-5 ">
                    <ReactPlayer width="100%" controls url={videoUrl} />
                  </div>
                </LazyLoad>
              )}
            </>
            {bgColor && (
              <div
                className={`bg-image position-absolute w-50 h-50 top-0 ${
                  flip ? "right-0" : "left-0"
                }`}
                style={overlayImageStyle(flip)}
              >
                {flip ? (
                  <div
                    className="d-none d-md-block"
                    style={{
                      background: bgColor,
                      width: "100%",
                      height: "100%",
                      borderBottomRightRadius: "30px",
                      borderTopLeftRadius: "30px",
                    }}
                  ></div>
                ) : (
                  <div
                    className="d-none d-md-block"
                    style={{
                      background: bgColor,
                      width: "100%",
                      height: "100%",
                      borderTopRightRadius: "30px",
                      borderBottomLeftRadius: "30px",
                    }}
                  ></div>
                )}
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export const overlayImageStyle = (flip) => ({
  zIndex: "-1",
  left: flip ? "unset" : 0,
  right: flip ? 0 : "unset",
});

export default TwoColumnFaqVideo;
