import { graphql, useStaticQuery } from "gatsby";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import { Helmet } from "react-helmet";
import CtaBanner from "../components/cta-banner";
import HeroBanner from "../components/hero";
import Layout from "../components/layout";
import Form from "react-bootstrap/Form";
import { CiSearch } from "react-icons/ci";
import TwoColumnFaqVideo from "../components/two-column-faq-video";

const FaqPage = () => {
	const data = useStaticQuery(graphql`
		query {
			site {
				siteMetadata {
					siteUrl
				}
			}
			allWpFaqVideoType {
				nodes {
					name
					slug
				}
			}
			allWpFaqVideo(sort: { fields: dateGmt, order: DESC }) {
				nodes {
					title
					slug
					faqVideoTypes {
						nodes {
							name
							slug
						}
					}
					faqVideoPostFields {
						faqVideoTagline
						faqVideoIconColor
						faqButtonTextColor
						faqVideoLink {
							target
							title
							url
						}
						faqVideo {
							node {
								altText
								sourceUrl
								mediaItemUrl
							}
						}
					}
				}
			}
			pageData: wpPage(slug: { eq: "faq-videos" }) {
				seoFields {
					localBusinessSchema
					metaDescription
					opengraphDescription
					opengraphTitle
					title
					image {
						node {
							altText
							sourceUrl
							mediaDetails {
								height
								width
							}
						}
					}
				}
				faqVideoPageContent {
					faqVideoBannerSection {
						faqVideoBannerSectionContent
						faqVideoBannerSectionHeading
						faqVideoBannerSectionBackground {
							node {
								altText
								sourceUrl
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: AVIF
											quality: 30
											transformOptions: { cropFocus: CENTER, fit: COVER }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
					faqTextButtonCta {
						faqCtaContent
						faqCtaHeading
						faqCtaButton {
							target
							title
							url
						}
						faqCtaBackgroundImage {
							node {
								altText
								sourceUrl
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: AVIF
											quality: 30
											transformOptions: { cropFocus: CENTER, fit: COVER }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;
	const {
		pageData: { seoFields, faqVideoPageContent },
		allWpFaqVideo,
		allWpFaqVideoType,
	} = data;

	const [allFaqVideos, setAllFaqVideos] = useState([]);
	const [selectedFilter, setSelectedFilter] = useState(null);
	const [searchVal, setSearchVal] = useState("");
	const [finalsearchVal, setFinalSearchVal] = useState(null);
	const [numOfItemsToShow, setNumberOfItems] = useState(3);

	const [categoriesWithFaqVideos, setCategoriesWithFaqVideos] = useState([]);

	useEffect(() => {
		const faqVideoCountsByCategory = {};

		// Count case studies per category
		allWpFaqVideo.nodes.forEach((study) => {
			study.faqVideoTypes?.nodes.forEach((type) => {
				if (faqVideoCountsByCategory[type.slug]) {
					faqVideoCountsByCategory[type.slug]++;
				} else {
					faqVideoCountsByCategory[type.slug] = 1;
				}
			});
		});

		// Filter categories that have case studies
		const filteredCategories = allWpFaqVideoType.nodes.filter(
			(category) => faqVideoCountsByCategory[category.slug]
		);

		setCategoriesWithFaqVideos(filteredCategories);
	}, [allWpFaqVideo, allWpFaqVideoType]);

	useEffect(() => {
		setAllFaqVideos(allWpFaqVideo.nodes);
	}, [allWpFaqVideo]);

	useEffect(() => {
		function filterObjects(array) {
			return array.filter((obj) => {
				const containsKeyword =
					!finalsearchVal ||
					obj.title.toLowerCase().includes(finalsearchVal.toLowerCase());

				const isInCategory =
					!selectedFilter ||
					(obj.faqVideoTypes &&
						obj.faqVideoTypes.nodes.some((e) => e.slug === selectedFilter));

				return containsKeyword && isInCategory;
			});
		}

		const filteredData = filterObjects(allWpFaqVideo.nodes);
		setAllFaqVideos(filteredData);
	}, [selectedFilter, finalsearchVal]);

	const handleChangeInput = (val) => {
		setFinalSearchVal(val);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		setFinalSearchVal(searchVal);
	};

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Faq",
				item: {
					url: `${siteUrl}/faq`,
					id: `${siteUrl}/faq`,
				},
			},
		],
	};

	return (
		<>
			<Helmet>
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<GatsbySeo
				title={seoFields?.title}
				description={seoFields?.metaDescription}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/faq`,
					title: `${seoFields?.opengraphTitle}`,
					description: `${seoFields?.opengraphDescription}`,
					images: [
						{
							url: `${seoFields?.image?.node.sourceUrl}`,
							width: `${seoFields?.image?.node.mediaDetails.width}`,
							height: `${seoFields?.image?.node.mediaDetails.height}`,
							alt: `${seoFields?.image?.node.altText}`,
						},
					],
				}}
			/>

			<Layout>
				<HeroBanner
					heading={
						faqVideoPageContent?.faqVideoBannerSection
							.faqVideoBannerSectionHeading
					}
					content={
						faqVideoPageContent?.faqVideoBannerSection
							.faqVideoBannerSectionContent
					}
					bgImage={
						faqVideoPageContent?.faqVideoBannerSection
							.faqVideoBannerSectionBackground?.node?.sourceUrl
					}
					showUnderline={false}
					textAlign={"center"}
					columns={7}
				/>
				<section className="pt-5 pt-md-8">
					<Container>
						<Row className={`align-items-lg-center`}>
							<Col lg={12} className="text-start pe-lg-6">
								<div className="d-flex w-100 align-items-center">
									<Form className="w-100" onSubmit={handleSubmit}>
										<Row className="mb-4 text-center text-md-start">
											<Col className="position-relative">
												<Form.Control
													type="text"
													placeholder="Search"
													className="mr-sm-1 border-light-black py-2 rounded text-light-black"
													style={{ height: "52px", paddingLeft: "36px" }}
													onChange={(e) => handleChangeInput(e.target.value)}
													value={finalsearchVal}
												/>
												<CiSearch
													className="position-absolute d-flex align-items-center h-100"
													style={{ top: 0, bottom: 0, left: "24px" }}
												/>
											</Col>
										</Row>
									</Form>
								</div>
							</Col>
						</Row>
						<Row>
							<Col>
								<div className="d-flex flex-wrap align-items-center mt-3">
									<Button
										style={{ borderRadius: "200px" }}
										onClick={() => setSelectedFilter(null)}
										className={`me-2 mb-2 py-2 px-4 cat-buttons ${
											selectedFilter === null
												? "text-primary cat-button-active"
												: "text-category-grey custom-cat-button"
										}`}
										variant={`${
											selectedFilter === null
												? "outline-primary"
												: "outline-category-grey"
										}`}
									>
										View all
									</Button>
									{allWpFaqVideoType &&
										categoriesWithFaqVideos
											.sort((a, b) => a.name.localeCompare(b.name))
											.map((item, index) => (
												<Button
													style={{ borderRadius: "200px" }}
													key={index}
													onClick={() => setSelectedFilter(item.slug)}
													className={`me-2 mb-2 py-2 px-4 cat-buttons ${
														selectedFilter === item.slug
															? "text-primary cat-button-active"
															: "text-category-grey custom-cat-button"
													}`}
													variant={`${
														selectedFilter === item.slug
															? "outline-primary"
															: "outline-category-grey"
													}`}
												>
													{item.name}
												</Button>
											))}
								</div>
							</Col>
						</Row>
					</Container>
				</section>

				{allFaqVideos.length > 0 && (
					<>
						{allFaqVideos.map((item, i) => {
							const flip = i % 2 === 0;
							return (
								<TwoColumnFaqVideo
									heading={item?.title}
									tagline={item?.faqVideoPostFields.faqVideoTagline}
									btnCta1={item?.faqVideoPostFields.faqVideoLink}
									video={item?.faqVideoPostFields.faqVideo?.node}
									bgColor={item?.faqVideoPostFields.faqVideoIconColor}
									flip={flip}
									textColor={
										item?.faqVideoPostFields?.faqButtonTextColor ?? "#fff"
									}
								/>
							);
						})}
					</>
				)}
				<CtaBanner
					useBgImage
					heading={faqVideoPageContent?.faqTextButtonCta.faqCtaHeading}
					content={faqVideoPageContent?.faqTextButtonCta.faqCtaContent}
					btnCta1={faqVideoPageContent?.faqTextButtonCta.faqCtaButton}
					bgImage={
						faqVideoPageContent?.faqTextButtonCta.faqCtaBackgroundImage?.node
					}
				/>
			</Layout>
		</>
	);
};

export default FaqPage;
