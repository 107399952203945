import React, { useState, useEffect } from "react";
import { useForm, ValidationError } from "@formspree/react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { InlineWidget } from "react-calendly";

function ContactFormCalendly({ formButtonText, formTitle, slug }) {
	const [state, handleSubmit] = useForm("myzywedk");
	const [gclid, setGclid] = useState("");
	const [formSubmitted, setFormSubmitted] = useState(false);

	const [utm, setUtm] = useState(""); // New state to store gclid

	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);
		const gclidParam = urlParams.get("gclid");
		const utmParam = urlParams.get("utm_medium");
		if (gclidParam) {
			setGclid(gclidParam); // Store the gclid in state if it exists
		}

		if (utmParam) {
			setUtm(utmParam); // Store the gclid in state if it exists
		}
	}, []);

	useEffect(() => {
		if (state.succeeded) {
			window.location.href = `https://zcal.co/t/ncz/introduction?id=${slug}`;
		}
	}, [state.succeeded]);

	const handleFormSubmit = (event) => {
		event.preventDefault();
		const formData = new FormData(event.target);
		const customerEmail = formData.get("email");
		const customerName = formData.get("name");

		if (typeof window !== "undefined" && window.dataLayer) {
			window.dataLayer.push({
				event: "generate_lead",
				form_name: "ContactForm",
				customer_email: customerEmail,
			});
		}

		handleSubmit(event);
	};

	return (
		<Row className="align-items-lg-center justify-content-lg-center mt-4">
			<Col lg={7} xl={5}>
				<div className="bg-white p-4 m-lg-4 rounded-4">
					<Form className="w-100" onSubmit={handleFormSubmit}>
						{formTitle && <h6 className="mb-3">{formTitle}</h6>}
						<Row>
							<Col>
								<Form.Group className="mb-3" controlId="name">
									<Form.Label className="montserrat-bold medium-text">
										Name <span className="text-danger">*</span>
									</Form.Label>
									<Form.Control
										name="name"
										type="text"
										style={{ height: "48px" }}
										required
									/>
								</Form.Group>
								<ValidationError
									prefix="Name"
									field="name"
									errors={state.errors}
								/>
							</Col>
						</Row>
						<Row>
							<Col>
								<Form.Group className="mb-3" controlId="email">
									<Form.Label className="montserrat-bold medium-text">
										Email <span className="text-danger">*</span>
									</Form.Label>
									<Form.Control
										type="email"
										name="email"
										style={{ height: "48px" }}
										required
									/>
								</Form.Group>
								<ValidationError
									prefix="Email"
									field="email"
									errors={state.errors}
								/>
							</Col>
						</Row>
						<Row>
							<Col>
								<Form.Group className="mb-4" controlId="phone">
									<Form.Label className="montserrat-bold medium-text">
										Phone <span className="text-danger">*</span>
									</Form.Label>
									<Form.Control
										type="tel"
										name="phone"
										style={{ height: "48px" }}
										required
									/>
								</Form.Group>
							</Col>
						</Row>
						{/* Hidden fields */}
						<Form.Group controlId="gclid" className="d-none">
							<Form.Control type="hidden" name="gclid" value={gclid} />
						</Form.Group>
						<Form.Group controlId="utmId" className="d-none">
							<Form.Control type="hidden" name="utmId" value={utm} />
						</Form.Group>
						<Form.Group controlId="page" className="d-none">
							<Form.Control type="hidden" name="page" value={slug} />
						</Form.Group>
						<Button
							size="small"
							className="px-4 py-3 mt-2 montserrat-bold fs-6 btn btn-secondary w-100"
							type="submit"
							id="contact-send-btn"
							disabled={state.submitting}
						>
							{formButtonText || "Next"}
						</Button>
					</Form>
				</div>
			</Col>

			{/* <Col>
					<Row>
						<Col className="d-xl-none">
							<InlineWidget
								className="calender"
								styles={{ minWidth: "100%", height: "950px" }}
								url="https://calendly.com/nczjourney/30min?back=1&month=2024-11"
							/>
						</Col>
						<Col className="d-none d-xl-block">
							<InlineWidget
								className="calender"
								styles={{ minWidth: "100%", height: "700px" }}
								url="https://calendly.com/nczjourney/30min?back=1&month=2024-11"
							/>
						</Col>
					</Row>
				</Col> */}
		</Row>
	);
}

export default ContactFormCalendly;
